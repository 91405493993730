<template>
  <div>
    <!-- <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click.stop="openNav = !openNav" height="18" width="22"></v-app-bar-nav-icon> -->
    <v-btn class="ml-4 hidden-sm-and-down" light elevation="1" icon v-if="$vuetify.breakpoint.mdAndDown" @click.stop="openNav = !openNav" small>
      <img src="/static/svg/mobile-profile-open-nav.svg"/>
    </v-btn>
    <!-- calc(100vh - 100px) -->
    <v-navigation-drawer width="fix-content" :height="$vuetify.breakpoint.mdAndDown ? 'calc(100vh - 100px)' : ''" class="desktop-navigation-drawer hidden-sm-and-down" :class="$vuetify.breakpoint.mdAndDown ?'mt-2 ml-1': ''" v-model="openNav" :absolute="$vuetify.breakpoint.mdAndDown ? true :false" :left="$vuetify.breakpoint.mdAndDown ? true :false" :temporary="$vuetify.breakpoint.mdAndDown ? true :false"
      :permanent="$vuetify.breakpoint.mdAndDown ? false :true">
      <v-list nav dense>
        <v-list-item-group active-class="profile-nav-active">
          <v-list-item v-if="$vuetify.breakpoint.mdAndDown" class="pl-0 nav-btn" active-class="white" @click.stop="openNav = !openNav" >
            <v-list-item-title>
              <img src="/static/svg/mobile-profile-close-nav.svg"/>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="nav-title">
              {{ $t(`label.bankingProfile`) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name: routeName.DEPOSIT }">
            <v-list-item-icon>
              <account-deposit class="mobile-icon-deposit"></account-deposit>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.deposit`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name: routeName.WITHDRAWAL }">
            <v-list-item-icon>
              <account-withdrawal class="mobile-icon"></account-withdrawal>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.withdrawal`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name: routeName.TRANSACTION_HISTORY }">
            <v-list-item-icon>
              <account-history class="mobile-icon"></account-history>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.history`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name: routeName.TURNOVER }">
            <v-list-item-icon>
              <account-history class="mobile-icon"></account-history>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.turnoverHistory`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name: routeName.PLAYER_HISTORY }">
            <v-list-item-icon>
              <account-history class="mobile-icon"></account-history>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.playerHistory`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name: routeName.CLAIM_VOUCHER }">
            <v-list-item-icon>
              <account-reward class="mobile-icon"></account-reward>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.claimVoucher`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button">
            <v-list-item-title class="nav-title">
              {{ $t(`label.userProfile`) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name:routeName.PERSONAL}">
            <v-list-item-icon>
              <account-profile class="mobile-icon"></account-profile>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.myProfile`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name:routeName.INBOX}">
            <v-list-item-icon class="pt-1">
              <account-inbox class="mobile-icon"></account-inbox>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.inbox`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name:routeName.CHANGE_PASSWORD}">
            <v-list-item-icon>
              <account-password class="mobile-icon"></account-password>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.changePassword`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-list-item class="profile-nav-button" :to="{ name:routeName.BANK_ACCOUNT}">
            <v-list-item-icon>
              <account-bank class="mobile-icon"></account-bank>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.bankDetail`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name: routeName.REWARDS}">
            <v-list-item-icon>
              <account-reward class="mobile-icon"></account-reward>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.rewards`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" :to="{ name: routeName.VIP }">
            <v-list-item-icon>
              <account-vip class="mobile-icon"></account-vip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.vip`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="profile-nav-button" v-if="affiliateBonus.status == 1" :to="{ name: routeName.AFFILIATE_REFERRAL }">
            <v-list-item-icon>
              <account-vip class="mobile-icon"></account-vip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`label.referral`) }}</v-list-item-title>
            </v-list-item-content>
            <!--<v-list-item-content style="width: 0px;height: 40px;margin-left: 0px">
              <v-img src="/static/svg/game-icon-new.svg" width="25px" height="25px"></v-img>
            </v-list-item-content>-->
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- <v-divider class="my-5 mx-4"></v-divider>
                <div v-if="!isLoggedIn">
                <v-row>
                  <v-col cols=12 class="mx-4">
                    {{ $t(`label.welcomeToBrand`) }}
                  </v-col>
                </v-row>
                <v-row justify="space-between" class="mx-1">
                    <v-col cols="5">
                        <v-btn @click="this.openLoginDialog" color="black" class="mr-1 primary-button embedded-mobile-login-button font-weight-bold yellow--text">{{ $t(`button.login`) }}
                        </v-btn>
                    </v-col>
                    <v-col cols="7">
                        <app-button :customClass= "'buttonPrimary theme-button embedded-mobile-register-button'" :action="this.openRegisterDialog" :title="$t(`label.signUp`)"></app-button>
                    </v-col>
                </v-row>
                </div> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
  import {
    DevicePlatform
  } from '@/constants/enums'
  import {
    ROUTE_NAME
  } from '@/constants/route.constants'
  import {
    uiHelper
  } from '@/util'
  import AccountBank from '@/assets/svg/profile/bank.svg'
  import AccountDeposit from '@/assets/svg/profile/deposit.svg'
  import AccountHistory from '@/assets/svg/profile/history.svg'
  import AccountInbox from '@/assets/svg/profile/inbox.svg'
  import AccountPassword from '@/assets/svg/profile/password.svg'
  import AccountProfile from '@/assets/svg/profile/profile.svg'
  import AccountReward from '@/assets/svg/profile/reward.svg'
  import AccountVip from '@/assets/svg/profile/vip.svg'
  import AccountWithdrawal from '@/assets/svg/profile/withdrawal.svg'
  import { AFFILIATE_ONE_TIME_BONUS_DETAIL } from '@/store/affiliate.bonus.module'
  export default {
    name: 'appBankAndProfileSidePanel',
    components: {
      AccountBank,
      AccountDeposit,
      AccountHistory,
      AccountInbox,
      AccountPassword,
      AccountProfile,
      AccountReward,
      AccountVip,
      AccountWithdrawal
    },
    data: () => ({
      devicePlatform: DevicePlatform,
      uihelper: uiHelper,
      openNav: false,
      routeName: ROUTE_NAME,
      bankLink: [{
          text_key: 'deposit',
          routeName: ROUTE_NAME.DEPOSIT
        },
        {
          text_key: 'withdrawal',
          routeName: ROUTE_NAME.WITHDRAWAL
        },
        {
          text_key: 'transfer',
          routeName: ROUTE_NAME.TRANSFER
        },
        {
          text_key: 'history',
          routeName: ROUTE_NAME.TRANSACTION_HISTORY
        },
        {
          text_key: 'turnover',
          routeName: ROUTE_NAME.TURNOVER
        },
        {
          text_key: 'player-history',
          routeName: ROUTE_NAME.PLAYER_HISTORY
        },
        {
          text_key: 'bankAccount',
          routeName: ROUTE_NAME.BANK_ACCOUNT
        }
      ],
      profileLink: [{
          text_key: 'account',
          routeName: ROUTE_NAME.PROFILE
        },
        {
          text_key: 'personal',
          routeName: ROUTE_NAME.PERSONAL
        },
        {
          text_key: 'announcement',
          routeName: ROUTE_NAME.ANNOUNCEMENT
        },
        {
          text_key: 'referral',
          routeName: ROUTE_NAME.AFFILIATE_REFERRAL
        }
      ],
      affiliateLink: [{
          text_key: 'qrCode',
          routeName: ROUTE_NAME.AFFILIATE_LINK
        },
        {
          text_key: 'withdrawal',
          routeName: ROUTE_NAME.AFFILIATE_WITHDRAWAL
        },
        {
          text_key: 'history',
          routeName: ROUTE_NAME.AFFILIATE_TRANSACTION
        },
        {
          text_key: 'bankAccount',
          routeName: ROUTE_NAME.AFFILIATE_BANK_ACCOUNT
        }
      ]
    }),
    created() {},
    computed: {
      // navPermanent() {
      //   if($vuetify.breakpoint.mdAndDown)
      //     return false
      // }
      isLoggedIn() {
        return this.$store.state.member.isLoggedIn
      },
      affiliateBonus() {
        return this.$store.state.affiliateBonus.affiliateBonusDetail
      },
    },
    watch: {
      isLoggedIn() {
        this.getAffiliateBonusDetail()
      }
    },
    methods: {
      getAffiliateBonusDetail() {
        this.$store.dispatch(`${AFFILIATE_ONE_TIME_BONUS_DETAIL}`)
      },
    }
  }
</script>

<style lang="scss">
.desktop-navigation-drawer {
  font-size: 12px;
  border-radius: 20px;
  height: 660px;
  .nav-title{
    color:#ACACAC;
  }
  .profile-nav-button{
    .mobile-icon {
      fill: #3a3a3a;
      path {
        fill: #3a3a3a;
      }
    }
    .mobile-icon-deposit{
      fill: #3a3a3a !important;
    }
    // &:hover {
    //   color: #0C2AAB !important;
    //   .mobile-icon {
    //     fill: #0C2AAB !important;
    //     path {
    //       fill: #0C2AAB !important;
    //     }
    //   }
    //   .mobile-icon-deposit{
    //     fill: #0C2AAB !important;
    //   }
    // }
  }

  .nav-btn.theme--light.v-list-item--active::before{
    opacity: 0 !important;
  }
}
.profile-nav-active {
  background-color: #3a3a3a !important;
  color: #fff !important;
  .mobile-icon {
    fill: #fff !important;
    path {
      fill: #fff !important;
    }
  }
}
</style>
