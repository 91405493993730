<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : ''">
    <!-- <v-row no-gutters>
                        <v-col cols="12" :lg="9" :md="10" class="mx-auto px-4 pt-6">
                            <v-expansion-panels>
                                <v-expansion-panel style="border-radius: 20px   ; background-color:#282F5B" class="px-4"
                                >
                                    <v-expansion-panel-header>
                                        <v-row no-gutters>
                                            <v-col cols="12" lg="auto">
            <span :class="$vuetify.breakpoint.mdAndUp ? 'headline' : 'title'">
                          {{$t(`label.wallet`)}}
                        </span>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col cols="12" lg="auto">
                                                <v-row no-gutters>
                                                    <v-col cols="12" lg="auto">
                                                        <span :class="$vuetify.breakpoint.mdAndUp ? 'headline text-right' : 'title text-center'">
                                                            {{$t(`label.totalBalance`)}} :&nbsp;</span>
                                                    </v-col>
                                                    <v-col cols="12" lg="auto">
                                                        <span :class="['navigator_active--text', $vuetify.breakpoint.mdAndUp ? 'headline':'title']">{{memberWallet.totalBalance | currency}}</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-divider class="title_color--text"></v-divider>
                                        <v-row no-gutters class="py-4">
                                            <v-col cols="auto">
                                              <span :class="['title_color--text text-uppercase' , $vuetify.breakpoint.mdAndUp ? 'headline':'subtitle-1']">
                          {{$t(`label.gameWallet`)}}
                        </span>
                                            </v-col>
                                            <v-spacer>
                                            </v-spacer>
                                            <v-col cols="12" :lg="3">
                                                <v-list color="transparent" class="py-0">
                                                    <v-list-item class="py-0" v-for="(n,k) in eachWalletArr">
                                                        <v-list-item-content class="justify-start title_color--text sub_balance">
                                                            <v-row>
                                                                <v-col cols="12">
                                                                    {{ $t(`gameVendor.${n.walletCode.toLowerCase()}_long`) }}
                                                                    <v-tooltip bottom v-if="n.walletCode.toLowerCase() == 'main'">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-icon
                                                                                    color="title_color"
                                                                                    dark
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                            >
                                                                                info
                                                                            </v-icon>
</template>
                                                            <p>
                                                               {{$t(`label.toolSeamless`)}}
                                                            </p>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                            <v-list-item-content class="title_color--text justify-end sub_balance">
                                                {{n.balance | currency}}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                            <v-divider class="title_color--text"></v-divider>
                            <v-row no-gutters class="py-4">
                                <v-col cols="auto" :class="$vuetify.breakpoint.mdAndDown ? 'd-flex justify-center align-center' : ''">
                                  <span :class="['title_color--text text-uppercase' , $vuetify.breakpoint.mdAndUp ? 'headline':'subtitle-1']">
              {{$t(`label.bonusWallet`)}}
            </span>
                                </v-col>
                                <v-spacer>
                                </v-spacer>
                                <v-col cols="auto">
                                    <v-list color="transparent" class="py-0">
                                        <v-list-item class="py-0">
                                            <v-list-item-content class="title_color--text justify-end sub_balance">
                                                {{memberWallet.bonusBalance | currency}}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                            <v-divider class="title_color--text"></v-divider>
                            <v-row no-gutters class="py-4">
                                <v-col cols="6" :class="$vuetify.breakpoint.mdAndDown ? 'd-flex justify-center align-center' : ''">
                                  <span :class="['title_color--text text-uppercase' , $vuetify.breakpoint.mdAndUp ? 'headline':'subtitle-1']">
              {{$t(`label.withdrawableBalance`)}}
            </span>
                                </v-col>
                                <v-spacer>
                                </v-spacer>
                                <v-col cols="6" :lg="3">
                                    <v-list color="transparent" class="py-0">
                                        <v-list-item class="py-0">
                                            <v-list-item-content class="white--text justify-end sub_balance">
                                                {{memberWallet.withdrawableBalance | currency}}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row> -->
    <v-snackbar class="pa-0" light timeout="-1" v-model="alertShow" width="fit-content" style="z-index:1000 !important;top:4%" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <v-row v-if="$vuetify.breakpoint.lgAndUp" justify="center" no-gutters>
      <v-col cols="2" md="2" class="bank-profile-side-panel" :class="$vuetify.breakpoint.mdAndUp ? 'my-12' : 'my-4'">
        <app-bank-and-profile-side-panel></app-bank-and-profile-side-panel>
      </v-col>
      <v-col cols="10" :class="'my-12'">
        <router-view></router-view>
      </v-col>
    </v-row>
    <v-row v-else no-gutters>
      <v-col cols="1" class="bank-profile-side-panel hidden-sm-and-down" :class="$vuetify.breakpoint.mdAndUp ? 'my-12' : 'my-4'">
        <app-bank-and-profile-side-panel></app-bank-and-profile-side-panel>
      </v-col>
      <v-col cols="12" >
        <router-view></router-view>
      </v-col>
    </v-row>
    <app-referral class="hidden-md-and-down"></app-referral>
    <instant-popup-dialog :dialog-show="instantDialogShow" :max-width="438" :closeAction="this.closeInstantPopup"
                          :array-info="popupAnnouncement"></instant-popup-dialog>
  </div>
</template>

<script>
import { uiHelper } from '@/util'
import { SESSION } from '@/constants/constants'
import AppBankAndProfileSidePanel from '@/components/layout/BankAndProfileSideMenu.vue'
import { PAYMENT_MEMBER_BANK_ACCOUNT, PAYMENT_BANK } from '@/store/payment.module'
import { MEMBER_DETAIL } from '@/store/member.module'
import AppReferral from '@/components/member/referral'
import InstantPopupDialog from "@/components/InstantPopupDialog.vue";
export default {
  name: 'bankLayout',
  components: {
    AppBankAndProfileSidePanel,
    AppReferral,
    InstantPopupDialog
  },
  data: () => ({
    instantDialogShow: false,
    openNav: false,
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  computed: {
    popupAnnouncement() {
      let p = []
      let k = this.$store.state.announcement.popupAnnouncement.filter(x=>x.type==='payment')
      k.forEach(announcement => {
        // announcement.filter(x=>x.type==='payment')
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
              announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
                  ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
                  : announcement.titleLocale.find(x => x.language == 'en') != null
                      ? announcement.titleLocale.find(x => x.language == 'en').title
                      : announcement.titleLocale[0].title,
          message:
              announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
                  ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
                  : announcement.messageLocale.find(x => x.language == 'en') != null
                      ? announcement.messageLocale.find(x => x.language == 'en').message
                      : announcement.messageLocale[0].message
        })
      })
      if (p.length > 0) {
        this.openInstantPopup()
      }
      return p
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    eachWalletArr() {
      return _.orderBy(this.$store.state.member.walletBalance.wallets, 'isPrimary', 'desc')
    }
  },
  watch: {},
  created() {
    this.getMemberBankAccount()
    this.getBank()
    this.getMemberDetail()
    // this.showPaymentPopup();
  },
  methods: {
    openInstantPopup() {
      let dayClose = uiHelper.getCookie('popupdeposit')
      if (dayClose == null) {
        this.instantDialogShow = true
      }
    },
    closeInstantPopup() {
      let dayClose = uiHelper.getCookie('popupdeposit')
      if (dayClose == null) {
        dayClose = 'yes'
      }
      uiHelper.setCookie('popupdeposit', 'yes', 0.5)
      this.instantDialogShow = false
    },
    // showPaymentPopup(){
    //   if(this.popupAnnouncement && this.popupAnnouncement.lenght>0){
    //
    //   }
    // },
    getMemberDetail() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    getBank() {
      let obj = {
        bankName: '',
        bankCode: '',
        currency: uiHelper.getCurrency(),
        Page: '',
        ItemsPerpage: '',
        orderBy: '',
        order: ''
      }
      this.$store.dispatch(`${PAYMENT_BANK}`, {
        obj
      })
    },
    getMemberBankAccount() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.$store.dispatch(`${PAYMENT_MEMBER_BANK_ACCOUNT}`, {
        obj
      })
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    }
  }
}
</script>

<style lang="scss">
.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}
.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}
.desktop_card {
  border-radius: 20px !important;
  background-color: var(--v-title_color2-base) !important;
  height: 660px;
  label {
    color: #3a3a3a;
  }
  .v-card__title {
    margin-left: 26px;
  }
  .icon-btn {
    &:hover {
      fill: #0c2aab !important;
      path {
        fill: #0c2aab !important;
      }
    }
  }
}
.bank-detail-card {
  border-radius: 10px !important;
  margin: 0px 40px;
  border: 1px solid var(--v-base) !important;
  font-size: 14px;
  .v-card__title {
    padding: 0px 40px;
  }
  label {
    color: #3a3a3a;
  }
  label.field-name {
    color: #acacac;
  }
  .v-label {
    font-size: 14px;
  }
}
.title_color--text.sub_balance {
  font-size: 16px;
}
.v-list-item__content.sub_balance {
  padding-top: 0 !important;
}
.bank-account-card {
  width: 100%;
  border: 2px solid #acacac !important;
  border-radius: 10px !important;
  &.inComplete {
    border: 2px solid var(--v-secondary_2-base) !important;
  }
}
.bank-balance {
  background-color: #121734 !important;
  height: 36px;
  .v-divider {
    height: 100%;
    display: inline-block;
    border-color: red;
    border-right: 5px solid var(--v-navigator_active-base) !important;
  }
  .balance-amount {
    min-width: 100px;
  }
}
.bank-page-form {
  padding: 0px 20px 25px;
  max-width: 600px;
  margin: auto;
}
.payment-method-list-container {
  padding-bottom: 20px;
}
.payment-method-list-item {
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 10px;
  .payment-method-list-item-button {
    background-image: linear-gradient(to bottom, #273270, #1c244f);
    letter-spacing: 0;
    min-width: 150px !important;
    &.active {
      background-image: linear-gradient(to left, var(--v-primary-base), var(--v-primary_3-base));
    }
  }
}
.bank-navigation-button {
  color: #9c9ff3 !important;
  letter-spacing: 0;
  border-radius: 0px;
  padding: 24px !important;
  &.v-btn--active {
    span {
      font-weight: bold;
      color: var(--v-navigator_active-base);
    }
  }
  span {
    display: inline-block;
  }
}
.bank-page-title {
  border-bottom: 4px solid var(--v-title_color-base);
}
.bank-transaction-stepper {
  box-shadow: none;
  min-height: 350px;
}
.v-stepper.bank-transaction-stepper {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.deposit-page {
  min-height: 630px;
}
@media (max-width: 1500px) {
  // .bank-profile-side-panel {
  //     min-width: 250px;
  // }
}
@media (max-width: 1350px) {
}
@media (max-width: 1263px) {
  .bank-page-title {
    font-size: 16px;
  }
  .bank-navigation-button {
    padding: 10px 20px !important;
    min-height: auto;
    height: auto;
  }
  .payment-method-list-item .payment-method-list-item-button {
    padding: 12px !important;
    min-width: 120px !important;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 959px) {
  .desktop_card {
    border-radius: unset !important;
    height: 100%;
    background-color: #fff !important;
    font-size: 14px;
    padding-top: 10px !important;
    label {
      color: #3a3a3a;
    }
    .v-card__title {
      padding: 20px 0px 0px 20px;
      margin-left: 12px;
      label {
        font-size: 18px !important;
      }
    }
  }
  .bank-detail-card {
    border-radius: 10px !important;
    margin: 0px;
    padding: 0px;
    border: 1px solid var(--v-base) !important;
    font-size: 14px;
  }
}
@media (max-width: 599px) {
  .v-list-item__content {
    padding: 0 !important;
  }
}
@media (max-width: 425px) {
}
</style>
